<template>
    <div class="club-container" v-if="info">
        <div class="title">{{ "移动机器人创新实验室" | language }}</div>
        <div class="desc">{{ info.desc }}</div>
        <div class="list-title">{{ "智联创新实验室" | language }}</div>
        <swiper ref="mySwiper" :options="swiperOptions">
            <swiper-slide v-for="i in labsLength" :key="'slide' + i">
                <div class="club-list">
                    <div
                        v-for="item in info.labs.slice(i * 9 - 9, 9 * i)"
                        :key="item._id"
                        @click="handleClick(item)"
                        class="club-item click-op"
                    >
                        <img
                            v-real-img="item.logo.src"
                            alt=""
                            class="club-img"
                        />
                        <div class="item-name">{{ item.name }}</div>
                    </div>
                </div>
            </swiper-slide>
            <div class="swiper-pagination" slot="pagination"></div>
        </swiper>
        <br />
        <div class="row-container">
            <div class="plan">
                <div class="_title mr-b2">{{ "创新计划" | language }}</div>
                <ul class="plan-list">
                    <li
                        class="plan-item click-op"
                        v-for="(item, index) in info.plan.slice(0, 4)"
                        :key="index"
                    >
                        {{ item }}
                    </li>
                </ul>
            </div>
            <div class="plan mr-l18 sm-hidden">
                <div class="_title mr-b2">{{ "最新动态" | language }}</div>
                <ul class="plan-list">
                    <li
                        class="plan-item click-op"
                        v-for="(item, index) in info.news"
                        :key="item._id + index"
                    >
                        {{ item.title }}
                    </li>
                </ul>
                <div
                    class="more click-op mr-t3"
                    @click="getMore"
                    v-if="total > 0"
                >
                    <div class="line"></div>
                    <div class="text mr-l1">{{ "查看更多" | language }}</div>
                    <Icon type="ios-arrow-forward" :size="16" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
export default {
    data() {
        return {
            info: null,
            pageSize: 6,
            pageNum: 0,
            total: 0,
            swiperOptions: {
                autoHeight: false,
                pagination: {
                    el: ".swiper-pagination",
                    clickable: true
                },
                direction: "vertical"
            }
        };
    },
    created() {
        this.fetchData();
    },
    computed: {
        labsLength() {
            let len = this.info?.labs?.length ?? 0;
            return len > 0 ? Math.ceil(len / 9) : 0;
        }
    },
    methods: {
        handleClick(item) {
            window.open(item.link);
        },
        getMore() {
            if (this.info.news.length < 6) {
                return this.$Message.error("没有更多的动态啦");
            }
            this.fetchNews();
        },
        async fetchNews() {
            let res = await this.$http.get(
                `/innovation/news/more?type=LabModel&pageSize=${this.pageSize}&pageNum=${this.pageNum}`
            );
            this.pageNum += 1;

            this.$set(this.info, "news", [...this.info.news, ...res.result]);
            this.total = res.result.length;
        },
        async fetchData() {
            this.info = await this.$http.get("/innovation/lab/summary");
            this.total = this.info.news.length;
        }
    }
};
</script>

<style lang="less" scoped>
.swiper-container {
    width: 100%;
    height: 240px;
    .club-list {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;
        .club-item {
            width: 33.33%;
            padding: 10px;
            display: flex;
            align-items: center;
            // width: 241px;
            .club-img {
                margin-right: 15px;
                width: 60px;
                height: 60px;
                background: #d8d8d8;
                object-fit: cover;
            }
            .club-name {
                width: 166px;
                font-size: 16px;
                opacity: 0.9;
                line-height: 29px;
            }
        }
    }
}
.club-container {
    text-align: left;
    .title {
        opacity: 0.9;
        font-size: 30px;
        letter-spacing: 0;
        line-height: 32px;
        margin-bottom: 20px;
    }
    .desc {
        margin-bottom: 40px;
        opacity: 0.6;
        font-size: 16px;

        letter-spacing: 0;
        line-height: 29px;
    }
    .list-title {
        opacity: 0.9;
        font-size: 22px;
        letter-spacing: 0;
        line-height: 32px;
        margin-bottom: 30px;
    }
    .row-container {
        margin-bottom: 80px;
        display: flex;
        align-items: flex-start;
        .plan {
            ._title {
                opacity: 0.9;
                font-size: 22px;
                line-height: 32px;
            }
            .plan-list {
                width: 400px;
                margin-left: 15px;
                .plan-item {
                    width: 100%;
                    opacity: 0.9;
                    font-size: 16px;
                    line-height: 29px;
                }
            }
            .more {
                display: flex;
                align-items: center;
                .line {
                    width: 4px;
                    height: 12px;
                    background-color: #f55e5a;
                }
                .text {
                    margin-right: 5px;
                    opacity: 0.9;
                    font-size: 16px;
                    line-height: 29px;
                }
            }
        }
    }
}
@media screen and (max-width: 960px) {
    .club-container {
        .row-container {
            .plan {
                .plan-list {
                    width: 100%;
                }
            }
        }
    }
    .swiper-container {
        height: 300px;
        .club-list {
            .club-item {
                flex-direction: column;
                .club-img {
                    margin-right: 0;
                }
            }
        }
    }
}
</style>
